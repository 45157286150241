<template>
  <div>
    <page-title :heading="$t('accounting.lang_nav_lieferschein')" :subheading="$t('accounting.lang_nav_editlieferschein')" :icon=icon show-previous-button></page-title>

    <div class="app-main__inner">
      <EditDeliveryNoteComponent/>
    </div>
  </div>
</template>

<script>
import PageTitle from "@/Layout/Components/PageTitle";
import EditDeliveryNoteComponent from "@/components/billing/deliverynote/EditDeliveryNoteComponent";

export default {
  name: "EditDeliveryNote",
  components: {EditDeliveryNoteComponent, PageTitle},
  data: () => ({
    icon: 'pe-7s-note2 icon-gradient bg-tempting-azure',
  })
}
</script>

<style scoped>

</style>

